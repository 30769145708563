import React from 'react';

import './Page.css';




export const About = ()  => (
   
    <div>    
        

        <div className="box1">                                             
            <img class="about-image" src="/Images/Tom4.png" alt=""/>     
        </div>                         
                            
        <div className="box1">               
                            
            <h2 className="hometext1">My name is Thomas R. Thompson, I go by Tom or Tommy, and I solve business problems with software.  
                I have over six years of Website experience and over 25 years of business experience in Virginia 
                State Government as Director of Utilites for Virginia Commonwealth University and Energy Manager for the Virginia 
                Department of Energy.  In the private sector my focus was building environmental controls using the Java-based 
                Niagara Framework. With a BS in Mathematics/Computer Science and MBA my education and experience gives me the background to understand and 
                solve business problems.
            </h2>
        </div>                          


                          
                       
                    
                

                
                   
                    
                    
                

                    
    </div>

               

		

)

export default About



