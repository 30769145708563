import React from 'react';

import './Page.css';




export const Information = ()  => (
  
            <div>  

                    <div>
                        
                    
                        <div className="box2">
                                <h2 className="informationtext1">Links to other websites and information</h2>
                        </div>
       
                   
                    
                    
                    

                    
                    
                       
                    <div className="box3">
                                    
                                    <h2 className="informationtext2">Linkedin - https://linkedin.com/in/tomatthomasrthompsonllc  
                           

                       
                                    
                                    <br></br><br></br>Github - https://github.com/thomasrthompsonllc 
                           
                                    
                                    <br></br><br></br>Stackoverflow – https://stackoverflow.com/users/8463493/tom-thompson 
                          
                          
                                    
                                    <br></br><br></br> HTML, CSS, JavaScript - website – https://thomasrthompsonllc.github.io/github.io/ 
                          
                           
                                    
                                    <br></br><br></br>WordPress website – https://wordpress.thomasrthompsonllc.com 
                          
                            
                                    
                                    <br></br><br></br>Twitter -  https://twitter.com/tomthomastech 
                       
                           

                                   
                                    <br></br><br></br>Vue - http://thomasrthompsonllc.s3-website-us-east-1.amazonaws.com/</h2> 
                                  
                                    </div>       
                  
                                    </div>
</div>
		

)

export default Information;



