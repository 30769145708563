import React from 'react';
import './Page.css';
const Footer = () => {
    return(
    
        <div className="footer-container">
      
    
            
                    {/* Column1 */}
                    <ul className="list-unstyled">
                        <h4 className="listtext">Thomas R. Thompson, LLC</h4>
                       
                        <li className="listtext">Phone - 804-513-1423</li>
                        <li className="listtext">Fax - 804-968-4411</li> 
                       
                        </ul>
                 
                    {/* Column2*/}
                    
                     
                    <ul className="list-unstyled">
                        <h4 className="listtext">Address</h4>
                        <li className="listtext" >Thomas R. Thompson, LLC</li>
                        <li className="listtext">10307 West Broad Street #114</li> 
                        <li className="listtext">Glen Allen, VA 23060</li> 
                    </ul>
                   
                    {/* Column3 */}
                    <ul className="list-unstyled">
                        <h4 className="listtext">Hours</h4>
                       
                        <li className="listtext">Monday - Friday</li>
                        <li className="listtext">8:00am - 4:00pm</li> 
                        
                    </ul>
                   
          
             
             
                        <h2 className="copyrighttext"> Copyright 2022 Thomas R. Thompson, LLC</h2>    
               {/*               &copy;{new Date().getFullYear()} Thomas R. Thompson, LLC | All rights reserved */}

               

</div> 

            
          
           
       
    )

}
export default Footer;