import React from 'react';

import './Page.css';



export const Services = ()  => (
    /* Home section */
            <div>                    

                    
        <div className="box2">  
                               <h1 className="hometext2" >Tools I use to build and maintain websites.</h1> 
        </div>
        <div className="box7">
            <img class="services-image" src="/Images/React.png"alt="" /> 
            <img class="services-image" src="/Images/JavaScript.png" alt="e"/>  
            <img class="services-image" src="/Images/WordPress.png" alt=""/>  
            <img class="services-image" src="/Images/HTML.png"alt="" />  
            <img class="services-image" src="/Images/ReactNative.png" alt=""/>  
            <img class="services-image" src="/Images/CSS.png" alt=""/> 
            <img class="services-image" src="/Images/GitHub.png" alt=""/>   
        </div>
                       
               
                
                    {/* Services section */}
                <div className="box4">  
                    <h2 className="section-title">Services I provide</h2>
                </div> 
                <div className="box4"> 
                    <h2 className="hometext3">I develop and maintain websites.  I am not satisfied until you are satisfied.    </h2>
                </div>     
                      
                <div className="box5">                            
                    <img class="cartoon3-image" src="/Images/Tom.jpg" alt=""/>     
                </div>              
                            
                       
                

                    
                    
                    
                    
                

                    
                    </div>

               

		

)

export default Services;



