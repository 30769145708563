import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './Components/About';
import Information from './Components/Information';
import Contact from './Components/Contact';
import Services from './Components/Services';
import Footer from './Components/Footer';
function App() {
	return (
		<div className="app">

		
			<Router>
				<Navbar />
				<Routes>

				
					<Route  path="/" element={<Home />} />
				

					<Route  path="/About" element={<About />} />
					
					<Route  path="/Services" element={<Services />} />
					<Route  path="/Information" element={<Information/>} />
					<Route  path="/Contact" element={<Contact/>} />
					
				</Routes>
				<Footer />
			</Router>
		</div>
	);
}

export default App;