import React from 'react';
import './Page.css';
const Home = () => {
    return (
       
        <div>
            <div className="box1">
                <div  className="background">         
                    <h2 className="text">Introducing<br></br><br></br> Tom Thompson<br></br><br></br>Software Engineer</h2>   
                </div>
            </div> 

            <div className="box6">
            </div>

            <div className="box2">
                <h1 className="hometext3">This website was built with react using functional components and hooks. CSS was used to style the website without using a framework such as BootStrap or Material UI.</h1> 
            </div>

            <div className="box6">
            </div>

            <div className="box3">
                <h1 className="hometext3">My primary focus is front-end development with React but I have worked with WordPress, Vue, Angular, and .NET.  My skillset does include full-stack development with
                                    Node.js, PHP, or ASP.NET(C#) on the server side.  I am comfortable working various database structures, including MYSQL and PostgreSQL. I have hosted sites with cPanel, AWS, and Azure.</h1> 
            </div>

            <div className="box6">
            </div>
            
            <div className="box4">
                        <h1 className="hometext3">Below are some of the projects I have worked on and information on how I work. </h1> 
            </div>            

            <div className="box6">
            </div>

              <div className="box5">            
                        <h1 className="hometext1">
            
            
          
                            •	I have used component-based libraries such as Bootstrap, Material UI, etc. but prefer to use CSS to structure websites.<br></br><br></br>
                            •	Maintain version control with Git and GitHub<br></br><br></br>
                            •	Work with clients to define UI/UX requirements, build object-oriented web applications, and confirm <br></br>&emsp;expectations have been met at completion of project.<br></br><br></br>
                            •	Corrected WordPress website issues with financial calculations for company providing budget services.<br></br><br></br>
                            •	Developed WordPress website for educational provider offering support to disadvantaged youth.<br></br><br></br>
                            •	Developed websites with React and WordPress then hosted using cPanel with MySQL databases.<br></br><br></br>
                            •	Developed .Net(C#) application for stock trading with SQL database providing multiple inputs and <br></br>&emsp;graphical output expectations.<br></br><br></br>
                            •	Provided website maintenance including extension and theme updates, backups, etc.<br></br><br></br>
                            •	Developed inventory and time management .Net(C#) applications with interface to Access, SQL Server, <br></br>or MySQL database.<br></br><br></br>
                            •	Comfortable working with using different project management methodologies including waterfall, Scrum, <br></br>and Agile.<br></br><br></br>
                            •	Knowledge of CI/CD pipeline tools such as Gitlab and Jenkins.<br></br><br></br>
                            •	Testing with Web Driver IO (WDIO), knowledge of Jest and Enzyme<br></br><br></br>
                            </h1> 
                    </div>

        </div>




   
    )
}

export default Home;