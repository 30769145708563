import React from 'react';

import './Page.css';




export const Contact = ()  => (

            <div>  
                <div className="box1">             
                    <img class="cartoon3-image" src="/Images/cartoon.jpg"alt="" />     
                </div>               
                          
          
                

                    <div className="section-title">
                    
                    
                        
                        

                        
                        <h1 class="text-center">___________</h1>

            

                        <h2 className="section-title">Contact Information<br></br><br></br> Email - tom @thomasrthompsonllc.com</h2>
                        
                        <h1 class="text-center">___________</h1>
                               
                                    <img class="cartoon1-image" src="/Images/9.png"alt="" />     
                                
                           
                           
                         
                             
                                    <img class="cartoon2-image" src="/Images/10.png" alt=""/>     
                               
                           
                    
                    </div>

                </div>

		

)

export default Contact



